define("discourse/plugins/discourse-contact-plugin/discourse/templates/connectors/topic-above-posts/contact", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @outletArgs.model.tags}}
    {{#if (includes @outletArgs.model.tags "contact")}}
  
      <ContactForm />
  
    {{/if}}
  {{/if}}
  */
  {
    "id": "5zyHMCyF",
    "block": "[[[41,[30,1,[\"model\",\"tags\"]],[[[41,[28,[37,1],[[30,1,[\"model\",\"tags\"]],\"contact\"],null],[[[1,\"\\n    \"],[8,[39,2],null,null,null],[1,\"\\n\\n\"]],[]],null]],[]],null]],[\"@outletArgs\"],false,[\"if\",\"includes\",\"contact-form\"]]",
    "moduleName": "discourse/plugins/discourse-contact-plugin/discourse/templates/connectors/topic-above-posts/contact.hbs",
    "isStrictMode": false
  });
});