define("discourse/plugins/discourse-contact-plugin/discourse/routes/contact", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Route for the path `/contact` as defined in `../contact-route-map.js.es6`.
   */
  var _default = _exports.default = _discourse.default.extend({
    renderTemplate() {
      // Renders the template `../templates/contact.hbs`
      this.render("ContactForm");
    }
  });
});