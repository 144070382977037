define("discourse/plugins/discourse-contact-plugin/discourse/contact-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Links the path `/contact` to a route named `contact`. Named like this, a
   * route with the same name needs to be created in the `routes` directory.
   */
  var _default = _exports.default = {
    path: "contact",
    map() {
      this.route("contact");
    }
  };
});